/**
 * Reactflow Customization
 */
.react-flow__node {
  background-color: $white;
  border: 2px solid $gray-300;
  border-radius: 2px;
  padding: 0px;
  font-size: 11px;

  &.selected {
    border: 2px solid #ee00ff;
  }

  .btn-add-handle {
    padding: 0px 3px;
    border: 2px solid $gray-600;
    border-radius: 50%;
    font-size: 8px;
    line-height: 11px;
    background-color: #fff;

    position: absolute;
    top: 4px;

    &.left {
      left: -8px;
    }

    &.right {
      right: -8px;
    }
  }
}

.react-flow__edge {

  .react-flow__edge-path {
    stroke-width: 3 !important;
  }

  &.selected {

    .react-flow__edge-path {
      stroke: #ee00ff !important;
    }
  }
}

.react-flow__handle {
  width: 10px !important;
  height: 10px !important;

  &.react-flow__handle-left {
    background-color: $gray-600;
    left: -6px;
  }

  &.react-flow__handle-right {
    background-color: $gray-700;
    right: -6px;
  }
}

.react-flow__panel {
  box-shadow: 0px 0px 2px 0px $gray-400;

  .panel-item {
    background-color: #fff;
    padding: 8px 12px;
    font-size: 18px;
    border: 1px solid $gray-300;
    border-bottom: 0px;

    &:last-child {
      border-bottom: 1px solid $gray-300;
    }

    &:hover {
      background-color: $gray-200;
      cursor: pointer;
    }
  }

}


//
// Node Types

.react-flow__node-control_volume {
  width: 180px;
  border-color: $gray-500;

  .node-body {
    min-height: 60px;
  }
}

.react-flow__node-conservation {
  width: 150px;

  .node-body {
    min-height: 30px;
  }
}

.react-flow__node-source {
  width: 60px;
  border-color: #c9d306;
  border-radius: 12px;

  .node-body {
    background-color: #ebeeaa;
    min-height: 20px;
    border-radius: 10px;
  }
}

.react-flow__node-sink {
  width: 60px;
  border-color: #fabd4c;
  border-radius: 12px;

  .node-body {
    background-color: #ffe1a8;
    min-height: 20px;
    border-radius: 10px;
  }
}

//
// Edge Types



//
// Custom Node

.node-header {
  position: relative;
  background-color: $gray-200;
  font-size: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-color: $gray-400;

  p {
    margin-bottom: 0px;
  }

  small {
    color: $gray-600;
  }

  input {
    background-color: transparent;
    border: 0;
    padding-top: 2px;

    &:hover {
      background-color: $gray-200;
      cursor: pointer;
    }
  }

}

.node-body {
  font-size: 9px;

  .btn-xs {
    font-size: 8px;
    padding: 0px 8px;
  }

  .sec-info {
    margin: 2px 0px 4px 0px;
    padding: 4px 12px;

    .sec-title {
      font-weight: 800;
    }
  }
}

.node-title {
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 700;

  small {
    font-size: 12px;
    font-weight: 400;
  }
}

//
//