html,
body {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

.ant-table-content {
  font-size: $font-size-base;
  font-family: $font-family-base;

  .ant-table-thead {
    .ant-table-cell {
      padding: 10px 10px !important;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 16px 10px !important;
    }
  }
}

// Page Section

h1.sec-title {
  font-size: 1.25rem;
}

h4.sec-title {
  font-size: 1rem;
}

div.sec-info {
  margin-top: 8px;
  padding-top: 5px;
  padding-bottom: 8px;

  .sec-title {
    font-weight: 600;
  }

  p {
    margin-bottom: 2px;
  }
}

// Pagination

.pagination-cont {
  margin-top: 12px;
  margin-bottom: 8px;
}

// Table

table {
  $table-border: 1px solid
    darken(
      $color: $body-bg-alt,
      $amount: 5%,
    ) !important;

  thead {
    border: $table-border;
  }

  tr {
    border-left: $table-border;
    border-right: $table-border;

    &:last-child {
      border-bottom: $table-border;
    }
  }

  th {
    background-color: $body-bg-alt !important;
    font-weight: 600 !important;
  }

  td {
    border-top: $table-border;
  }

  .sub-text {
    font-size: 0.75rem;
    color: $gray-600;
    display: block;
  }
}

.table-compact {
  tr {
  }

  th,
  td {
    padding: 0px;
  }

  td {
  }
}

// Buttons and Button Container

.btn-default {
  font-size: $btn-label-font-size;
  border-radius: $btn-border-radius;
  transition: $btn-transition;
}

.btn-primary {
  --bs-btn-color: #{$white};
  --bs-btn-bg: #{$green};
  --bs-btn-border-color: #{$green};
  --bs-btn-hover-color: #{$white};
  --bs-btn-hover-bg: #{$dark-green};
  --bs-btn-hover-border-color: #{$cyan-green};
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #{$white};
  --bs-btn-active-bg: #{$cyan-green};
  --bs-btn-active-border-color: #{$cyan-green};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{$white};
  --bs-btn-disabled-bg: #{$green};
  --bs-btn-disabled-border-color: #{$green};
}

.btn-cont {
  button,
  .btn {
    margin: 2px 4px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

// icon size
.fa-2xs {
  font-size: 0.625em !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-readonly {
  input,
  select,
  textarea {
    border: 1px solid transparent;
    background-color: transparent;
    padding-left: 0;
  }

  input[disabled],
  select[disabled],
  textarea[disabled] {
    background-color: transparent;
  }
}

// Utitlities

.cursor-pointer {
  cursor: pointer;
}

.text-truncate-2 {
  // for truncate after 2-lines
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-3 {
  // for truncate after 3-lines
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Background

.bg-alt {
  background-color: $body-bg-alt;
}

// NavLeft

.nav-left {
  .nav-items {
    margin: 6px !important;
    padding: 0;
    width: 100% !important;

    li {
      list-style: none;
      text-align: center;
      padding: 6px 0px;
    }
  }

  .nav-item {
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 8px;

    &.active,
    &:hover {
      background-color: $gray-300;
    }

    a {
      display: block;
      text-decoration: none;
      margin: 4px 0;
    }

    span {
      display: block;
      color: $gray-800;
    }
  }
}

// Sidebar

.sidebar {
  .navbar-nav {
    .nav-item {
      font-size: 14px;
      padding-left: 20px;
    }

    .nav-links {
      &.active,
      &:hover {
        background-color: $gray-300;
      }
    }
  }
}

.nav-links {
  margin-top: 26px !important;
  padding: 0;

  li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
  }
}

.menu-items {
  padding: 12px 20px !important;
  font-size: 11px;
  font-weight: 600;

  &.active,
  &:hover {
    background-color: $gray-300;
  }

  &:first-child {
    margin-top: 0px;
  }
}

.menu-link {
  color: #000;
  text-decoration: none;

  .icon {
    height: 24px;
  }
}

// Canvas Page

.sidebar {
  .card-collapsible {
    border: 0;

    .card-header {
      cursor: pointer;
      padding: 4px 16px;
    }
  }

  .sec-info {
    font-size: 11px;
    border-bottom: 1px solid $gray-300;
    padding: 0px 0 4px;

    .sec-title {
      font-size: 13px;
      font-weight: 800;
    }
  }
}

.btn-xs {
  font-size: 10px;
  padding: 2px 10px;
}

.list-item-selectable {
  li {
    cursor: pointer;
  }
}

.list-graph-elems {
  li {
    margin: 6px 0px;
    display: flex;
    justify-content: space-between;

    &.active {
      font-weight: 800;
    }
  }

  i {
    margin-right: 6px;
  }
}

// Reactflow Elements

.cont-edge-label {
  span {
    font-size: 10px;
  }

  small {
    font-size: 8px;
  }
}

// Log View Modal
.log-view-modal {
  max-height: 600px;
  overflow-y: auto;
}
