input.inp-inline-edit {
  background-color: transparent;
  padding: 2px 4px;
  border: 1px solid transparent;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}
