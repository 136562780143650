//
// Layout :: Project (for project related pages)
//

.layout-project {

  .topbar {
    height: $topbar-height;
    border-bottom: 1px solid $gray-300;
  }

  .sidebar {
    top: $topbar-height;
    width: $sidebar-left-width;
    border: 0;
    padding-bottom: $topbar-height;

    &.sidebar-left {
      left: $nav-left-width;
      border-right: 1px solid $gray-300;
    }

    &.sidebar-right {
      right: 0;
      border-left: 1px solid $gray-300;
      width: $sidebar-right-width;
    }
  }

  .main-cont {
    top: $topbar-height;
    left: $nav-left-width;
    right: $sidebar-right-width;
    background-color: $gray-100;

    &.canvas-cont {
      left: $sidebar-left-width + $nav-left-width;
    }
  }

}
