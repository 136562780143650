//
// Core: Layout
//

// Variables
// ----------------------------------------------------------------------------

$topbar-height: 4rem;
$topbar-zindex: 1000;

$nav-left-width: 5rem;

$sidebar-left-width: 16rem;
$sidebar-right-width: 18rem;


// CSS
// ----------------------------------------------------------------------------

html,
body,
.page {
  min-width: 1280px;
}

// General :: ScrollBar

html.scroll-smooth {
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-500;
}



// Branding

.navbar-branding {
  height: $topbar-height;
  border-bottom: 1px solid $gray-300;
  padding: 4px 22px;

  &.left {
    width: $sidebar-left-width;
  }
}

// Page Setup

.page {
  position: relative;
  overflow: hidden;
  height: 100vh;

  .topbar {
    position: fixed;
    z-index: $topbar-zindex;
    top: 0;
    right: 0;
    left: 0;
    transition: all 0.5s ease;
    height: $topbar-height;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar-branding {
      width: $nav-left-width;
      background-color: $gray-100;
      border-right: 1px solid $gray-300;
      text-align: center;
    }
  }

  .page-wrapper {
    margin-top: $topbar-height;
    height: 100vh;
  }

  .nav {
    &.nav-left {
      position: absolute;
      // z-index: $topbar-zindex - 1;
      top: $topbar-height;
      height: 100%;
      width: $nav-left-width;
      overflow-y: auto;
      border-right: 1px solid $gray-300;
      background-color: $gray-100;
    }
  }

  .sidebar {
    position: absolute;
    // z-index: $topbar-zindex - 1;
    top: $topbar-height;
    height: 100%;
    transition: all 0.5s ease;
    overflow-y: auto;
  }

  .main-cont {
    position: absolute;
    // z-index: $topbar-zindex - 2;
    top: $topbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s ease;
    overflow-y: auto;
  }

  // Topbar Only (no sidebar)
  &.layout-topbar {
    .topbar {
      height: $topbar-height;
      border-bottom: 1px solid $gray-300;
    }

    .sidebar {
      width: 0;
      display: none;
    }

    .main-cont {
      top: $topbar-height;
    }
  }

  // Topbar and Sidebar (collapsible)
  &.layout-sidebar {
    .topbar {
      height: $topbar-height;
      border-bottom: 1px solid $gray-300;

      .navbar-branding {
        width: $sidebar-left-width;
        background-color: $gray-100;
        border-right: 1px solid $gray-300;
        text-align: left;
      }

      &.collapsed {
        margin-left: $nav-left-width;
      }
    }

    .sidebar, .sidebar-left {
      width: $sidebar-left-width;
      border-right: 1px solid $gray-300;

      &.collapsed {
        width: $nav-left-width;
      }
    }

    .main-cont {
      top: $topbar-height;
      left: $sidebar-left-width;

      &.collapsed {
        left: $nav-left-width;
      }
    }

    &.layout-nav-left {
      .sidebar-left {
        width: $sidebar-left-width + $nav-left-width;
  
        &.collapsed {
          width: 0;
        }
      }

      //
      .main-cont {
        left: $sidebar-left-width + $nav-left-width;
      }
    }
  }

}


// Main Content Setup

.content-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;

  .page-header {
    padding: 16px #{$grid-gutter-width} 12px;
    margin-bottom: 5px;

    display: flex !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;

    &.border {
      border: 0 !important;
      border-bottom: 1px solid $gray-300 !important;
    }

    &.shadow {
      box-shadow: 0px 0px 15px 0px rgba(25, 104, 252, 0.2) !important;
    }
  }

  .page-content {
    padding: 12px #{$grid-gutter-width} 24px;
  }
}

//
// Position Utilities

.position-top-0 {
  top: 0 !important;
}

.position-right-0 {
  right: 0 !important;
}

.position-bottom-0 {
  bottom: 0 !important;
}

.position-left-0 {
  left: 0 !important;
}


// Modal
.modal-backdrop {
  z-index: 2040;
}

.modal {
  z-index: 2050;
}

