// Component CSS
@import "../../assests/themes/variables";

$color-border: #dee2e6;

.card-collapsible {
  border-bottom: 1px solid $color-border;

  .card-header {
    label {
      margin-bottom: 0px;

      &.sub-text {
        font-style: italic;
        display: block;
      }
    }

    .icon-card-toggle {
      float: right;
      width: 16px;
      height: 16px;
    }
  }

  .card-body {
    padding: 0.5rem 1.25rem;
  }

  .card-header {
    background-color: transparent;
    border: 0;
    font-weight: 600;
  }

  .sub-text {
    font-size: 0.75rem;
    color: $gray-600;
  }
}
