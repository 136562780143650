.bs-docs-section {
  margin-top: 4em;

  .page-header h1 {
    padding: 2rem 0;
    font-size: 3rem;
  }
}


.bs-component {
  position: relative;

  > .btn, > .btn-group, .progress, .badge {
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }

  .card {
    margin-bottom: 1rem;
  }

  .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }

  .modal-dialog {
    width: 90%;
  }

  .popover {
    position: relative;
    display: inline-block;
    width: 220px;
    margin: 20px;
  }
  
}

.bs-component+.bs-component {
  margin-top: 1rem
}

.source-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  display: none;
  font-weight: 700;

  :hover {
    cursor: pointer;
  }

  i {
    pointer-events: none;
  }
}

.bs-component:hover .source-button {
  display: none;
}

@media(max-width: 767px) {
  .splash {
    padding-top: 8em
  }

  .splash .logo {
    width: 100px
  }

  .splash h1 {
    font-size: 2em
  }
}

@media(min-width: 992px) {
  .navbar-transparent {
    background: none !important
  }
}

